import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';

import { StringFilter, TextFilterType } from '../../types';

type ItemsSearchFilterType = {
  name?: TextFilterType;
  memberFullName?: StringFilter;
};

const MEMBER_FULL_NAME_SEARCH_SYMBOL = '@';

class ItemsSearchFilter {
  static setValue(search: string): ItemsSearchFilterType {
    if (isEmpty(search)) {
      return { memberFullName: undefined, name: undefined };
    }

    if (search.startsWith(MEMBER_FULL_NAME_SEARCH_SYMBOL)) {
      return { memberFullName: search.substring(1), name: undefined };
    }

    return { memberFullName: undefined, name: { ilike: search } };
  }

  static getValue(filters: ItemsSearchFilterType) {
    if (filters?.name?.ilike) {
      return filters.name.ilike;
    }

    if (isString(filters?.memberFullName)) {
      return `${MEMBER_FULL_NAME_SEARCH_SYMBOL}${filters.memberFullName}`;
    }
    return '';
  }
}

export default ItemsSearchFilter;
