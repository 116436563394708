import React, { Fragment, ReactNode } from 'react';
import size from 'lodash/size';

import {
  UUID,
  FetchItemsPage,
  FetchItemsLimit,
  FetchItemsTotalCount,
  FetchItemsIsPlaceholderData,
  FetchItemsFetched,
  FetchItemsErrorMessage,
  FetchItemsPaginateItems,
  FetchItemsPrefetchItems,
  I18nText,
  ClassName
} from '../../../../../types';

import { IndexTable } from '../IndexTable';

import { PaginationForm } from '../../../forms/PaginationForm';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { NoResults } from '../../../../../helpers/NoResults';
import { NextPureLinkHelper } from '../../../../../helpers/links/NextPureLinkHelper';
import { LoadingTableSkeleton } from '../../../../../helpers/LoadingTableSkeleton';

import { words } from '../../../../../locales/keys';
interface ItemsIndexTableWrapperItem {
  uuid: UUID;
}

interface ItemsIndexTableWrapperProps {
  items: ItemsIndexTableWrapperItem[];
  itemsFetched: FetchItemsFetched;
  itemsError: FetchItemsErrorMessage;
  itemsIsPlaceholderData: FetchItemsIsPlaceholderData;
  itemsPage: FetchItemsPage;
  itemsLimit: FetchItemsLimit;
  itemsTotalCount: FetchItemsTotalCount;
  paginateItems: FetchItemsPaginateItems;
  prefetchItems: FetchItemsPrefetchItems;
  scope: string;
  children: ReactNode;
  noResultsI18nText?: I18nText;
  noResultsBody?: ReactNode;
  indexTableClassName?: ClassName;
  scrollContainerClassName?: ClassName;
  viewAllItemsPath?: string;
  withoutPaginationForm?: boolean;
  settings?: ReactNode;
  skeletonRows?: number;
}

function ItemsIndexTableWrapper({
  items,
  itemsFetched,
  itemsError,
  itemsIsPlaceholderData,
  itemsPage,
  itemsLimit,
  itemsTotalCount,
  paginateItems,
  prefetchItems,
  scope,
  children,
  noResultsI18nText,
  noResultsBody,
  indexTableClassName,
  scrollContainerClassName,
  viewAllItemsPath,
  settings,
  withoutPaginationForm = false,
  skeletonRows
}: ItemsIndexTableWrapperProps) {
  if (itemsFetched && itemsTotalCount === 0) {
    return noResultsBody ? (
      <div className="text-center my-5">{noResultsBody}</div>
    ) : (
      <NoResults
        addErrorClassName="m-4"
        errorMessage={itemsError}
        noResultsI18nText={noResultsI18nText}
      />
    );
  }

  return (
    <Fragment>
      <AlertMessage addClassName="m-4" message={itemsError} />
      <LoadingTableSkeleton
        loaded={itemsIsPlaceholderData || itemsFetched}
        rows={skeletonRows}
      >
        {size(items) > 0 ? (
          <Fragment>
            <IndexTable
              settings={settings}
              scope={scope}
              className={indexTableClassName}
              scrollContainerClassName={scrollContainerClassName}
            >
              {children}
            </IndexTable>

            {viewAllItemsPath ? (
              <div className="text-center my-6">
                <NextPureLinkHelper
                  href={viewAllItemsPath}
                  className="py-2 pl-4 pr-4 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700"
                  i18nText={words.viewAll}
                />
              </div>
            ) : null}
          </Fragment>
        ) : null}
      </LoadingTableSkeleton>

      {withoutPaginationForm || !itemsFetched ? null : (
        <div className="px-4">
          <PaginationForm
            page={itemsPage}
            limit={itemsLimit}
            totalItemsCount={itemsTotalCount}
            onPaginate={paginateItems}
            onPaginateMouseEnter={prefetchItems}
          />
        </div>
      )}
    </Fragment>
  );
}

export default ItemsIndexTableWrapper;
